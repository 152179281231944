<template>
    <div class="news-main">
        <div class="site">
            <div @click="handleClickTab('all')" :class="tabCss.all">ALL</div>
            <div @click="handleClickTab('news')" :class="tabCss.news">NEWS</div>
            <div @click="handleClickTab('event')" :class="tabCss.event">EVENT</div>
            <div @click="handleClickTab('card')" :class="tabCss.card">CARD</div>
        </div>
        <div class="news-list">
            <!--所有文章-->
            <div>
                <div class="news-item" v-for="(v,k) in $store.state.indexData.all" :key="k" v-show="tabCss.all!=''">
                    <router-link :to="'/index/article?id='+v.id">
                        <div class="news-item-sub">
                            <div class="news-item-img">
                                <el-image
                                        style="width: 100%;height: 100%"
                                        :src="v.banner_img"
                                        fit="contain">
                                    >
                                </el-image>
                            </div>
                            <div class="news-item-lab">
                                <div>{{$moment(v.created_at).format("YYYY-MM-DD")}}</div>
                                <div
                                        v-for="(item,index) in $webConfig.articleTypes"
                                        :key="index"
                                        v-show="(item.type == v.type)"
                                        :class="handleLabClass(v.type)"
                                >
                                    {{item.name}}
                                </div>
                            </div>
                            <div class="news-item-desc">{{v.schema}}</div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!--新闻-->
            <div>
                <div class="news-item" v-for="(v,k) in $store.state.indexData.news" :key="k" v-show="tabCss.news!=''">
                    <router-link :to="'/index/article?id='+v.id">
                        <div class="news-item-sub">
                            <div class="news-item-img">
                                <el-image
                                        style="width: 100%;height: 100%"
                                        :src="v.banner_img"
                                        fit="scale-down">
                                    >
                                </el-image>
                            </div>
                            <div class="news-item-lab">
                                <div>{{$moment(v.created_at).format("YYYY-MM-DD")}}</div>
                                <div
                                        v-for="(item,index) in $webConfig.articleTypes"
                                        :key="index"
                                        v-show="(item.type == v.type)"
                                        :class="handleLabClass(v.type)"
                                >
                                    {{item.name}}
                                </div>
                            </div>
                            <div class="news-item-desc">{{v.schema}}</div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!--活动-->
            <div>
                <div class="news-item" v-for="(v,k) in $store.state.indexData.event" :key="k" v-show="tabCss.event!=''">
                    <router-link :to="'/index/article?id='+v.id">
                        <div class="news-item-sub">
                            <div class="news-item-img">
                                <el-image
                                        style="width: 100%;height: 100%"
                                        :src="v.banner_img"
                                        fit="scale-down">
                                    >
                                </el-image>
                            </div>
                            <div class="news-item-lab">
                                <div>{{$moment(v.created_at).format("YYYY-MM-DD")}}</div>
                                <div
                                        v-for="(item,index) in $webConfig.articleTypes"
                                        :key="index"
                                        v-show="(item.type == v.type)"
                                        :class="handleLabClass(v.type)"
                                >
                                    {{item.name}}
                                </div>
                            </div>
                            <div class="news-item-desc">{{v.schema}}</div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!--卡片-->
            <div>
                <div class="news-item" v-for="(v,k) in $store.state.indexData.dayCard" :key="k" v-show="tabCss.card!=''">
                    <router-link :to="'/index/article?id='+v.id">
                        <div class="news-item-sub">
                            <div class="news-item-img">
                                <el-image
                                        style="width: 100%;height: 100%"
                                        :src="v.banner_img"
                                        fit="scale-down">
                                    >
                                </el-image>
                            </div>
                            <div class="news-item-lab">
                                <div>{{$moment(v.created_at).format("YYYY-MM-DD")}}</div>
                                <div
                                        v-for="(item,index) in $webConfig.articleTypes"
                                        :key="index"
                                        v-show="(item.type == v.type)"
                                        :class="handleLabClass(v.type)"
                                >
                                    {{item.name}}
                                </div>
                            </div>
                            <div class="news-item-desc">{{v.schema}}</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="m-clear-both"></div>
        <!--更多-->
        <div class="article-more"><router-link to="/index/articleList">更多...</router-link></div>
    </div>
</template>

<script>
    export default {
        name: "news",
        data() {
            return {
                tabCss: {
                    all:"site-active",
                    news:"",
                    event:"",
                    card:"",
                }
            }
        },
        methods: {
            handleClickTab(tabName) {
                for (let tabCssKey in this.tabCss) {
                    this.tabCss[tabCssKey] = ""
                }
                this.tabCss[tabName] = "site-active"
            },
            handleLabClass(type) {
                switch (type) {
                    case 1:
                        return "news-item-lab_news";
                    case 2:
                        return "news-item-lab_card";
                    case 88:
                        return "news-item-lab_event";
                    default:
                        return "";
                }

            }
        },
    }
</script>

<style scoped>
    .news-main{
        padding: 0 10%;
        background-color: #111111;
    }
    .site{
        height: 44px;
        width: 100%;
        background-color: white;
    }
    .site > div{
        float: left;
        width: 25%;
        text-align: center;
        line-height: 44px;
        font-family: 'FOT-ChiaroStd-B', 'Avenir', Helvetica, Arial, sans-serif !important;
        font-weight:bold;
        font-size: 20px;
    }
    .site > div:hover{
        cursor: pointer;
    }
    .site-active{
        background-color:#111111;
        color: white;
    }

    .news-list{
        width: 100%;
    }
    .news-list > div{
        width: 100%;
        color: white;
    }
    .news-list > div > div{
        width: 23%;
        float: left;
        margin: 1%;
        text-align: center;
    }

    .news-item > a{
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
    }

    .news-item-img{
        width: 100%;
        height: 23vh;
    }
    .news-item-img img{
       /* width: 100%;*/
    }
    .news-item-lab > div{
        float: left;
        height: 26px;
        line-height: 26px;
        width: 50%;
    }
    .news-item-lab >div:first-child{
        color: white;
        background-color: #434343;
        text-align: center;
        font-size: 14px;
    }
    .news-item-lab >div{
        color: white;
        text-align: center;
        font-size: 14px;
    }

    .news-item-lab_news{
        background-color: #a21a31;
    }
    .news-item-lab_card{
        background-color: #7f8b00;
    }
    .news-item-lab_event{
        background-color: #0783a9;
    }


    .news-item-desc {
        clear: both;
        text-align: left;
        color: white;
        font-size: 0.1rem;
        background-color: #434343;
        height: 60px;
        line-height: 28px;
        padding: 5px;
        letter-spacing: 1px;
        /* 将对象作为弹性伸缩盒子模型显示 */
        display: -webkit-box;
        /* 设置或检索伸缩盒对象的子元素的排列方式 。 */
        -webkit-box-orient: vertical;
        /*  用来限制在一个块元素显示的文本的行数 */
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .article-more{
        text-align: center;
    }
    .article-more a{
        text-decoration: none;
        color: white;
        font-weight: bold;
        font-size: 22px;
        text-decoration: underline;
    }

</style>
