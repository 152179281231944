import { render, staticRenderFns } from "./card-video.vue?vue&type=template&id=f486e100&scoped=true&"
import script from "./card-video.vue?vue&type=script&lang=js&"
export * from "./card-video.vue?vue&type=script&lang=js&"
import style0 from "./card-video.vue?vue&type=style&index=0&id=f486e100&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f486e100",
  null
  
)

export default component.exports