<template>
    <div class="main">
        <!--轮播-->
        <div>
            <Banner></Banner>
        </div>
        <!--每日一卡和视频区-->
        <div>
            <CardVideo></CardVideo>
        </div>
        <div class="m-clear-both"></div>
        <!--新闻区-->
        <div>
            <News></News>
        </div>
        <div class="m-clear-both"></div>
    </div>
</template>

<script>
import Banner from './components/banner';
import CardVideo from './components/card-video';
import News from './components/news'
export default {
    name: "index",
    components: { Banner, CardVideo, News },
    data() {
        return {

        }
    },
    methods: {
        getIndexData() {
            this.$axios.post("site/article/getIndexData", {})
                .then((res) => {
                    if (res.data.code == 1000) {
                        this.$store.state.indexData = res.data.data
                    }
                })
        }
    },
    created() {
        this.getIndexData();
    }
}
</script>

<style scoped>
.main {
    padding-top: 30px;
}
</style>
