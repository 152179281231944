<template>
    <div>
        <el-carousel :interval="2000" type="card" height="50vh">
            <el-carousel-item v-for="item in $store.state.webConf.banner_scroll" :key="item.img">
                <a :href="item.url">
                    <el-image style="width: 100%;height: 100%"
                        :src="item.img + `?x-oss-process=image/resize,h_1000,w_1000`" fit="contain">
                    </el-image>
                </a>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    name: "banner",
    data() {
        return {
        }
    },
    created() {
    }
}
</script>

<style scoped></style>
