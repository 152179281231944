<template>
    <div>
        <div class="car-and-video">
            <div class="car-and-video-main">
                <div class="day-card m-fl">
                    <h1>每日一卡</h1>
                    <el-divider></el-divider>
                    <router-link to="/index/dayCardDetail" v-if="selectData.length > 0">
                        <template v-for="(i, index) in selectData">
                            <el-image :key="i.id" v-show="cindex == index" style="height: 100%;width: 80%"
                                :src="i.img + '?x-oss-process=image/resize,h_300,w_300'" fit="fill">
                            </el-image>
                        </template>
                    </router-link>
                    <router-link to="" v-if="selectData.length == 0">
                        <el-image style="height: 100%;width: 80%"
                            src="https://bushiroad.oss-cn-beijing.aliyuncs.com/index/EsTlDGCBCSi5kbzoZVuIklszJkddprK31653981140.jpg?x-oss-process=image/resize,h_800,w_800"
                            fit="fill">
                        </el-image>
                    </router-link>
                </div>
                <div class="banner-video m-fl">
                    <div class="banner">
                        <a :href="$store.state.webConf.banner_ad[0].url"
                            v-if="$store.state.webConf.banner_ad && $store.state.webConf.banner_ad.length > 0">
                            <el-image style="width: 100%; height: 100%" :src="$store.state.webConf.banner_ad[0].img"
                                fit="fill">
                            </el-image>
                        </a>
                    </div>
                    <div class="video">
                        <h2>卡片战斗先导者的频道</h2>
                        <el-divider></el-divider>
                        <div class="video-item">
                            <div class="m-fl">
                                <div class="video-banner">
                                    <span>{{ $webConfig.bilibili1.title }}</span>
                                    <a target="_blank" :href="$webConfig.bilibili1.url">跳转查看</a>
                                </div>
                                <div class="index-video-item" v-html="$store.state.webConf.show_video1"></div>
                            </div>
                            <div class="m-fr">
                                <div class="video-banner">
                                    <span>{{ $webConfig.bilibili2.title }}</span>
                                    <a target="_blank" :href="$webConfig.bilibili2.url">跳转查看</a>
                                </div>
                                <div class="index-video-item" v-html="$store.state.webConf.show_video2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-clear-both"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "card-video",
    data() {
        return {
            selectData: ["https://bushiroad.oss-cn-beijing.aliyuncs.com/index/EsTlDGCBCSi5kbzoZVuIklszJkddprK31653981140.jpg?x-oss-process=image/resize,h_800,w_800"],
            cindex: 0
        }
    },
    methods: {
        getData() {
            this.$axios.post("site/siteDayCards/getDayCards", this.form)
                .then((res) => {
                    if (res.data.code == 1000) {
                        this.selectData = res.data.data.selectData;
                        let max = this.selectData.length;
                        if (max > 0) {
                            this.timer = setInterval(() => {
                                this.cindex++
                                if (this.cindex > max - 1) {
                                    this.cindex = 0;
                                }
                            }, 5000)
                        }
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
        }
    },
    created() {
        this.getData();
    },
    destroyed() {
        clearInterval(this.timer)
    },
}
</script>

<style scoped>
.car-and-video {
    width: 100%;
    height: 530px;
    padding-top: 20px;
}

.car-and-video-main {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
}

@media screen and (max-width: 1388px) {
    .car-and-video-main {
        width: 80%;
    }
}

.day-card {
    width: 25%;
}

.day-card h1 {
    font-weight: bold;
    font-size: 20px;
}

.banner-video {
    width: 65%;
    margin-left: 5%;
}

.video-item {
    width: 100%;
}

.video-item>div {
    margin: 2%;
    width: 45%;
    position: relative;
}

.video-banner {
    /*position: absolute;*/
    height: 40px;
    background-color: black;
    width: 99.9%;
    opacity: 0.8;
    color: white;
    font-weight: bold;
    line-height: 40px;
}

.video-banner>a {
    text-decoration: none;
    /*display: block;*/
    color: white;
    border: 1px solid white;
    float: right;
    display: block;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 5px;
    margin-top: 5px;
    margin-right: 2%;

}

.video-banner>span {
    margin-left: 5%;
}
</style>
